import React from 'react'
import PropTypes from 'prop-types'

// * FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCheckCircle,
	faShoppingBag,
	faClock,
	faCheck,
	faTimes,
	faWrench,
	faPhone,
	faMapMarker,
	faEnvelope,
	faShoppingCart,
	faComment,
	faCamera,
	faSearch,
	faChevronLeft,
	faChevronRight,
	faThumbsUp,
	faShareAlt,
	faQuoteLeft,
	faQuoteRight,
	faStar,
	faStarHalf,
	faExclamationTriangle,
	faUser,
	faCube,
	faSwimmingPool,
	faInfoCircle,
	faCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
library.add(
	faCheckCircle,
	faShoppingBag,
	faClock,
	faCheck,
	faTimes,
	faWrench,
	faPhone,
	faMapMarker,
	faEnvelope,
	faShoppingCart,
	faComment,
	faCamera,
	faSearch,
	faChevronLeft,
	faChevronRight,
	faThumbsUp,
	faShareAlt,
	faQuoteLeft,
	faQuoteRight,
	faStar,
	faStarHalf,
	faExclamationTriangle,
	faUser,
	faCube,
	faSwimmingPool,
	faInfoCircle,
	faCircle,
	faYoutube
)

import './index.scss'

const Layout = ({ children }) => <div className="Store">{children}</div>

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
